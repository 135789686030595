@font-face {
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-family: Saira Extra Condensed;
  src: url("fonts/saira-extra-condensed-latin-700-normal.e9e9b255.woff2") format("woff2");
  unicode-range: U+41-5A, U+20;
}

@font-face {
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-family: Saira Extra Condensed;
  src: url("fonts/saira-extra-condensed-latin-500-normal.502bed95.woff2") format("woff2");
  unicode-range: U+20-7F;
  -subfont-text: "@";
}

@font-face {
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-family: Josefin Sans;
  src: url("fonts/josefin-sans-latin-400-normal.d4343632.woff2") format("woff2");
  unicode-range: U+20-7F;
}

@font-face {
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-family: Josefin Sans;
  src: url("fonts/josefin-sans-latin-700-normal.baead87d.woff2") format("woff2");
  unicode-range: U+20-7F;
}
/*# sourceMappingURL=fonts.d2db8208.css.map */
