@use "~/node_modules/@fontsource/saira-extra-condensed/scss/mixins" as SEC;
@use "~/node_modules/@fontsource/josefin-sans/scss/mixins" as JS;

SEC.$fontDir: "~/node_modules/@fontsource/saira-extra-condensed/files";
JS.$fontDir: "~/node_modules/@fontsource/josefin-sans/files";

$display: swap;
$subset: latin;
$style: normal;

// stylelint-disable-next-line scss/operator-no-unspaced
$unicode-latin-text: U+20-7F;
// stylelint-disable-next-line scss/operator-no-unspaced
$unicode-latin-caps: U+41-5A, U+20;

@font-face {
    $weight: 700;

    font-display: $display;
    font-style: $style;
    font-weight: $weight;
    font-family: "#{SEC.$fontName}";
    src: url("#{SEC.$fontDir}/#{SEC.$fontId}-#{$subset}-#{$weight}-#{$style}.woff2") format("woff2");
    unicode-range: $unicode-latin-caps;
}

@font-face {
    $weight: 500;

    font-display: $display;
    font-style: $style;
    font-weight: $weight;
    font-family: "#{SEC.$fontName}";
    src: url("#{SEC.$fontDir}/#{SEC.$fontId}-#{$subset}-#{$weight}-#{$style}.woff2") format("woff2");
    unicode-range: $unicode-latin-text;
    -subfont-text: "@";
}

@font-face {
    $weight: 400;

    font-display: $display;
    font-style: $style;
    font-weight: $weight;
    font-family: "#{JS.$fontName}";
    src: url("#{JS.$fontDir}/#{JS.$fontId}-#{$subset}-#{$weight}-#{$style}.woff2") format("woff2");
    unicode-range: $unicode-latin-text;
}

@font-face {
    $weight: 700;

    font-display: $display;
    font-style: $style;
    font-weight: $weight;
    font-family: "#{JS.$fontName}";
    src: url("#{JS.$fontDir}/#{JS.$fontId}-#{$subset}-#{$weight}-#{$style}.woff2") format("woff2");
    unicode-range: $unicode-latin-text;
}

// @include SEC.fontFaceCustom(
//     $weight: 700,
//     $unicodeRange: true,
//     $unicodeRangeValues: $unicode-latin-caps,
// );
// @include SEC.fontFaceCustom(
//     $weight: 500,
//     $unicodeRange: true,
//     $unicodeRangeValues: $unicode-latin-text,
// );
// @include JS.fontFaceCustom(
//     $unicodeRange: true,
//     $unicodeRangeValues: $unicode-latin-text,
// );
// @include JS.fontFaceCustom(
//     $weight: 700,
//     $unicodeRange: true,
//     $unicodeRangeValues: $unicode-latin-text,
// );
